import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout/Layout";
import Modules from "../components/Modules/Modules";
import { Helmet } from "react-helmet";
import "./index.scss";

const Homepage = ({ data, errors, location }) => {
    if (errors) return <Layout errors={errors} />;

    const { page } = data;
    const href = location.href;

    // const seoPageData = {
    //     title: page.pageContent.seo?.title || page.title,
    //     description: page.pageContent.seo?.description,
    //     keywords: page.pageContent.seo?.keywords,
    //     noIndex: page.pageContent.seo?.noindex,
    //     data: page.pageContent.seo?.data,
    // };

    const {
        description: seoDescription,
        keywords: seoKeywords,
        noindex: seoNoIndex,
        title: seoTitle,
        data: seoData,
        altCanonical,
    } = page.content.seo;

    const seoPageData = {
        title: seoTitle ? seoTitle : page.title,
        description: seoDescription ? seoDescription : "",
        keywords: seoKeywords ? seoKeywords : null,
        noIndex: seoNoIndex ? seoNoIndex : false,
        data: seoData ? seoData : false,
        altCanonical: altCanonical,
        href: href,
    };

    return (
        <Layout seoData={seoPageData} heroContent={page.content.hero}>
            {page.content && page.content.modules && <Modules modulesData={page.content.modules} />}
        </Layout>
    );
};

export default Homepage;

export const query = graphql`
    query IndexPageQuery {
        page: sanityPage(pageTitle: { eq: "Home" }) {
            title: pageTitle
            slug {
                current
            }
            id
            content: homeContent {
                seo {
                    description
                    title
                    keywords
                    noindex
                    data
                    altCanonical
                }
                hero: heroBanner {
                    eyebrow
                    title: heroTitle {
                        style
                        _key
                        _type
                        children {
                            _type
                            _key
                            marks
                            text
                        }
                    }
                    marqueeText
                    marqueeImages {
                        ...ImageWithPreview
                        asset {
                            metadata {
                                dimensions {
                                    width
                                    height
                                }
                            }
                            filename
                            url
                        }
                    }
                    primaryImage {
                        ...ImageWithPreview
                    }
                    primaryalt
                    secondaryImage {
                        ...ImageWithPreview
                    }
                    secondaryalt
                    backdropImage {
                        ...ImageWithPreview
                    }
                    ctaButtonOne {
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        title
                    }
                    ctaButtonTwo {
                        title
                        linkTo {
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                    }
                    subtext
                }
                modules {
                    ... on SanityCarousel {
                        _key
                        _type
                        backdropImage {
                            ...ImageWithPreview
                        }
                        text
                        title {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        simpleLink {
                            title
                            external
                            internal {
                                slug {
                                    current
                                }
                            }
                        }
                        slides {
                            image {
                                ...ImageWithPreview
                                asset {
                                    metadata {
                                        dimensions {
                                            width
                                            height
                                        }
                                    }
                                    url
                                }
                            }
                            title
                            lineTwo
                            lineThree
                            lineOne
                        }
                    }
                    ... on SanityTestimonials {
                        _key
                        _type
                        testimonialList {
                            jobTitle
                            name
                            quote
                            logoAlt
                            image {
                                ...ImageWithPreview
                            }
                            logo {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityDemoForm {
                        _key
                        _type
                        showDemoForm
                    }

                    ... on SanityHomeSteps {
                        _key
                        _type
                        title: stepsTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        stepsBulletTitle {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        imageSizing
                        stepsList {
                            title
                            text
                            image {
                                ...ImageWithPreview
                            }
                            alt
                            icon {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                            ctaText
                            ctaLink {
                                external
                                internal {
                                    slug {
                                        current
                                    }
                                }
                            }
                        }
                    }

                    ... on SanityStats {
                        backdropImage {
                            ...ImageWithPreview
                        }
                        _key
                        _type
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityTwoColumns {
                        _key
                        _type
                        twoColumnsTabs {
                            _type
                            titleWithAccent {
                                style
                                list
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            reverse
                            tabsList {
                                title
                                text
                                tabName
                                bullets
                                image {
                                    ...ImageWithPreview
                                    asset {
                                        url
                                    }
                                }
                                alt
                                popups {
                                    headline
                                    text
                                    isNotification
                                    timestamp
                                    name
                                    icon {
                                        ...ImageWithPreview
                                    }
                                    gridColumnDesktop
                                    gridRowDesktop
                                    gridColumnMobile
                                    gridRowMobile
                                    mobileHide
                                    desktopHide
                                    isImage
                                    image {
                                        ...ImageWithPreview
                                        asset {
                                            metadata {
                                                dimensions {
                                                    width
                                                    height
                                                }
                                            }
                                            url
                                        }
                                    }
                                }
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        swappable {
                            _type
                            _key
                            title: titleWithAccent {
                                style
                                _key
                                _type
                                children {
                                    _type
                                    _key
                                    marks
                                    text
                                }
                            }
                            columnOne {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        marginTop
                                        alt
                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        popups {
                                            headline
                                            text
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        simpleLink {
                                            external
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            title
                                        }
                                        text
                                        title: titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                    }
                                    ... on SanityColumnImages {
                                        _key
                                        _type
                                        image {
                                            ...ImageWithPreview
                                        }
                                        alt
                                        marginTop

                                        backdropImage {
                                            ...ImageWithPreview
                                        }
                                        larger
                                        marginTop
                                        popups {
                                            headline
                                            text
                                            isNotification
                                            timestamp
                                            name
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            gridColumnDesktop
                                            gridRowDesktop
                                            gridColumnMobile
                                            gridRowMobile
                                            mobileHide
                                            desktopHide
                                            isImage
                                            image {
                                                ...ImageWithPreview
                                                asset {
                                                    metadata {
                                                        dimensions {
                                                            width
                                                            height
                                                        }
                                                    }
                                                    url
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubLinks {
                                        _key
                                        _type
                                        subLinksList {
                                            icon {
                                                ...ImageWithPreview
                                            }
                                            simpleLink {
                                                external
                                                title
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            title
                                            text
                                            image {
                                                ...ImageWithPreview
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityThreeColumns {
                        _key
                        _type
                        threeColumnOne {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnTwo {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                        threeColumnThree {
                            text
                            title
                            image {
                                ...ImageWithPreview
                            }
                            backdropImage {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityLiveCounter {
                        _key
                        _type
                        heading
                        number
                        ctaText
                        simpleLink {
                            title
                            internal {
                                slug {
                                    current
                                }
                            }
                            external
                        }
                    }
                    ... on SanityReviewsSection {
                        _key
                        _type
                        backdropImage {
                            ...ImageWithPreview
                        }
                        titleWithAccent {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        twoColumnsOne {
                            _type
                            columnOne {
                                _type
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        text
                                        titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        simpleLink {
                                            title
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            external
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            text
                                            title
                                            image {
                                                ...ImageWithPreview
                                                asset {
                                                    metadata {
                                                        dimensions {
                                                            width
                                                            height
                                                        }
                                                    }
                                                }
                                            }
                                            linkTo {
                                                external
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubTools {
                                        _key
                                        _type
                                        subToolsList {
                                            _key
                                            text
                                            title
                                            images {
                                                ...ImageWithPreview
                                                asset {
                                                    metadata {
                                                        dimensions {
                                                            width
                                                            height
                                                        }
                                                    }
                                                }
                                            }
                                            linkTo {
                                                external
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        twoColumnsTwo {
                            _type
                            columnOne {
                                _type
                                columnTypes {
                                    ... on SanityColumnDetails {
                                        _key
                                        _type
                                        text
                                        titleWithAccent {
                                            style
                                            _key
                                            _type
                                            children {
                                                _type
                                                _key
                                                marks
                                                text
                                            }
                                        }
                                        simpleLink {
                                            title
                                            internal {
                                                slug {
                                                    current
                                                }
                                            }
                                            external
                                        }
                                    }
                                }
                            }
                            columnTwo {
                                columnTypes {
                                    ... on SanitySubColumns {
                                        _key
                                        _type
                                        subColumnsList {
                                            text
                                            title
                                            image {
                                                ...ImageWithPreview
                                                asset {
                                                    metadata {
                                                        dimensions {
                                                            width
                                                            height
                                                        }
                                                    }
                                                }
                                            }
                                            linkTo {
                                                external
                                                internal {
                                                    slug {
                                                        current
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    ... on SanitySubStars {
                                        _key
                                        _type
                                        subStarsList {
                                            _key
                                            ratingText
                                            ratingNumber
                                            image {
                                                ...ImageWithPreview
                                                asset {
                                                    metadata {
                                                        dimensions {
                                                            width
                                                            height
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on SanityStats {
                        _key
                        _type
                        backdropImage {
                            ...ImageWithPreview
                        }
                        title {
                            style
                            list
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        statOne {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statThree {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                        statTwo {
                            text
                            number
                            icon {
                                ...ImageWithPreview
                            }
                        }
                    }
                    ... on SanityVideos {
                        _key
                        _type
                        backdropImage {
                            ...ImageWithPreview
                            asset {
                                metadata {
                                    dimensions {
                                        width
                                        height
                                    }
                                }
                                url
                            }
                        }
                        simpleLink {
                            title
                            internal {
                                slug {
                                    current
                                }
                            }
                            external
                        }
                        text
                        title {
                            style
                            _key
                            _type
                            children {
                                _type
                                _key
                                marks
                                text
                            }
                        }
                        videoOne {
                            youtubeLink
                            videoTitle
                            videoText
                            thumbnail {
                                ...ImageWithPreview
                                asset {
                                    metadata {
                                        dimensions {
                                            width
                                            height
                                        }
                                    }
                                    url
                                }
                            }
                            previewClip {
                                asset {
                                    url
                                }
                            }
                        }
                        videoTwo {
                            youtubeLink
                            videoTitle
                            videoText
                            thumbnail {
                                ...ImageWithPreview
                                asset {
                                    metadata {
                                        dimensions {
                                            width
                                            height
                                        }
                                    }
                                    url
                                }
                            }
                            previewClip {
                                asset {
                                    url
                                }
                            }
                        }
                        videoThree {
                            youtubeLink
                            videoTitle
                            videoText
                            thumbnail {
                                ...ImageWithPreview
                                asset {
                                    metadata {
                                        dimensions {
                                            width
                                            height
                                        }
                                    }
                                    url
                                }
                            }
                            previewClip {
                                asset {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
